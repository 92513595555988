/* page colors */
.bg-color-events {
    background-color: #ffdb6e;
}

.bg-color-about {
    background-color: #dd584b;
}

.bg-color-rent {
    background-color: #e690b0;
}

.bg-color-english {
    background-color: #eca158;
}

.title-case {
    text-transform: capitalize;
}

.upper-case {
    text-transform: uppercase;
}

/* aktuella, pågående, etc*/
.headline-left-aligned {
    text-transform: uppercase;
    margin-top: 1em;
    margin-bottom: 2em;
}

.header-title h1 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 16pt;
}

.shop-item-column-content h1 {
    font-family: 'Work Sans Medium', sans-serif;
    font-weight: 600;
    font-size: 16pt;
}

/* page title */
h1 {
    font-family: Rubik, serif;
    font-size: 22pt;
    color: #000;

    margin: 0;
    padding: 0;
}

h1 a {
    text-decoration: none;
    color: #000;
}

/* dates and tags */
h2 {
    font-family: 'Work Sans', sans-serif;
    font-size: 10pt;
    font-weight: bold;
    line-height: 8pt;
}

/* upcoming, archive, etc */
h3 {
    font-family: 'Work Sans', sans-serif;
    font-size: 12pt;
    font-weight: bold;
    letter-spacing: .2em;
}

/* editorial text headlines */
h4 {
    font-family: 'EB Garamond', serif;
    line-height: 24pt;
    font-size: 16pt;
    margin-top: 0;
    margin-bottom: 0;
}

footer h1 {
    font-size: 14pt;
    font-weight: bold;
}

footer a {
    color: #000000;
    text-decoration: underline;
    font-family: 'Work Sans', sans-serif;
    font-size: 14pt;
    text-decoration-thickness: 1px;
}

main p a {
    font-family: 'Work Sans', sans-serif;
    line-height: 20pt;
    font-size: 11pt;
    text-decoration: underline;
    color: #000000;
}

main p a:hover {
    color: blue;
}

/* article content text */
p, button {
    font-family: 'EB Garamond', serif;
    line-height: 20px;
    font-size: 13pt;
}

body {
    background: #f5f5f5;
}

/****************/
/* Menu styles */
/****************/
.mbh-header {
    padding-left: 4em;
    padding-right: 4em;
}

.mbh-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
}

.mbh-header-wrapper img {
    width: 140px;
}

.header-title {
    font-family: Rubik, serif;
    display: inline-block;
    text-align: center;
}

nav.header-nav {
    display: flex;
}

nav.header-nav a {
    margin-left: 2em;
    font-size: 11pt;
    font-family: Rubik, serif;
    text-decoration: none;
    color: #000;
}

a.active-nav {
    border-bottom: 4px solid #000;
}

article {
    white-space: pre-wrap;
    padding-top: 1em;
    padding-bottom: 1em;
}
/*********************************/
/* Upcoming events on first page */
/*********************************/

section {
    padding: 2em 4em 0;
}

section.upcoming-events {
    display: block;
}

@media (min-width: 1024px) {
    section.upcoming-events article {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin-bottom: 48px;
    }
}

@media (max-width: 1024px) {
    section.upcoming-events article {
        display: block;
        text-align: center;
    }

    section.upcoming-events article.text-content h1 {
        text-align: center;
    }
}

section.upcoming-events article .text-content {
    flex: 1;
}

@media (max-width: 1024px) {
    section.upcoming-events article .article-main-image {
        flex: 1;
        margin-bottom: 2em;
    }
}

@media (min-width: 1024px) {
    section.upcoming-events article .article-main-image {
        flex: 2;
        padding-left: 4em;
    }
}

section.upcoming-events h1 {
    text-align: center;
}

/************************************************/
/* Events placed in an n x 3 grid on first page */
/************************************************/
@media (min-width: 1024px) {
    .grid-or-block {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 4em;
    }
}

/* On mobile / small screens only show as a block */
@media (max-width: 1024px) {
    .grid-or-block {
        display: block;
    }
}

.grid-or-block h1 {
    text-align: center;
}

/**************************************************/
/* Books placed in an n x 4 grid on the shop page */
/**************************************************/
@media (min-width: 1024px) {
    .store-grid-or-block {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 4em;
    }
}

/* On mobile / small screens only show as a block */
@media (max-width: 1024px) {
    .store-grid-or-block {
        display: block;
    }
}

.store-grid-or-block h1 {
    text-align: left;
}

@media (min-width: 1024px) {
    section.archive article {
        max-width: 25%;
    }
}

/* Individual store items */

section.store-item {
    display: block;
}

@media (min-width: 1024px) {
    section.store-item article {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin-bottom: 48px;
    }
}

@media (max-width: 1024px) {
    section.store-item article {
        display: block;
        text-align: center;
    }

    section.store-item article.text-content h1 {
        text-align: center;
    }
}

section.store-item article .text-content {
    flex: 2;
}

@media (max-width: 1024px) {
    section.store-item article .article-main-image {
        flex: 1;
        margin-bottom: 2em;
    }
}

@media (min-width: 1024px) {
    section.upcoming-events article .article-main-image {
        flex: 2;
        padding-right: 4em;
    }
}

/* Shop items on grid list */
.shop-item-column {
    display: grid;
    grid-auto-rows: 1fr;
}

.shop-item-column-image {
    align-self: end;
}

.shop-item-column-image img {
    max-height: 400px;
}

.shop-item-column-content {
    align-self: center;
    text-align: left;

}

.shop-detail {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 4em;
    padding: 0 10em;
}

.shop-item-image {
    max-width: 550px;
}
/****
/* random / mis
 */

.wide-margin {
}

/* hack to get h3 aligned */
.wide-margin h3 {
    text-align: left;
    text-transform: uppercase;
}

.wide-margin h4 {
    text-align: left;
}

@media (min-width: 1280px) {
    .wide-margin {
        padding: 0 20% 0;
    }
}

.wide-margin p {
    text-align: left;
}

article .cover-photo {
    margin-top: 32px;
}

hr {
    height: 8px;
    border-color: #000000;
    background: #000000;
    margin-bottom: 2em;
}

.align-left {
    text-align: left;
}

@media screen and (min-width: 1024px) {
    /* Used for big images on the first page (desktop) */
    .article-main-image {
        display: inline-block;
        flex: 2;
    }

    .article-main-image img {
        width: 100%;
    }
    article img {
        max-width: 100%;
    }
}

/* images on mobile spans the whole width of the screen */
@media screen and (max-width: 1024px) {
    article img {
        width: calc(100% + 8em);
        margin-left: -4em;
    }
}

.article-left-content {
    text-align: left;
    white-space: pre-wrap;
}

.article-center-content {
    text-align: center;
    white-space: pre-wrap;
}

.headline-overflow {
    /* force centered headlines to be overflowing both to the left and right */
    margin-left: -15%;
    margin-right: -15%;
}

footer {
    border-top: 1px solid #aaa;
}

@media screen and (min-width: 1024px) {
    footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1em;
    }
}

@media screen and (max-width: 1024px) {
    footer {
        padding: 1em;
        text-align: center;
    }
}

footer .footer-element {
    flex: 1;
    padding: 2em;
    text-align: center;
}

footer p {
    font-family: 'Work Sans', sans-serif;
    font-size: 12pt;
}

footer a {
    font-size: 12pt;
}

p.text-align-left {
    text-align: left;
}

.breadcrumbs {
    margin-bottom: 2em;
    margin-left: 1em;
}

.breadcrumbs a {
    color: #000;
    font-family: 'Work Sans', sans-serif;
    font-size: 10pt;
    font-weight: bold;
    line-height: 8pt;
    text-decoration: none;
}

/* image caption */
img + em {
    text-align: right;
    font-family: 'EB Garamond', serif;
    line-height: 22pt;
    font-size: 14pt;
    font-style: normal;
    color: darkgray;
}

/* load more button */
button {
    width: 108px;
    padding: 8px;
    border: none;
    background: none;
}

.button-down img {
    width: 32px;
}

.center {
    text-align: center;
}
