.mobile-container {
    display: flex;
    flex-direction: row;
}

.mobile-header {
    display: flex;
    height: 80px;
    text-align: center;
}

.mobile-header img {
    height: 66px;
}

.mobile-links {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 80px);
    background: whitesmoke;
    overflow-y: hidden;
}

.mobile-links > div {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100% - 80px);
}

.mobile-nav-link {
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000;
}

.mobile-nav-link > a {
    font-family: Rubik, serif;
    font-size: 17px;
    line-height: 1.5em;
    color: #000;
    text-decoration: none;
}

.flex-item-one {
    flex: 1 1;
}

.hamburger-menu {
    flex: 1 1;
    justify-content: center;
}

.logo {
    flex: 2;
    padding-top: 8px;
}

.mobile-nav-icon {
    font-family: Rubik, serif;
    font-size: 32pt;
}

.active {
    background: #ffdb6e;
    color: white;
}
